<template>
  <v-container fluid>
    <v-card v-if="advertisementsSettings.filter" class="mb-3">
      <v-card-actions class="py-0 px-5">
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field v-model="advertisementsSettings.search" label="Meklēt" prepend-icon="fa-search" single-line hide-details/>
          </v-col>
          <v-col cols="12" md="2">
            <v-select v-model="advertisementsSettings.status" label="Status" prepend-icon="fa-toggle-on" :items="advertisements" item-text="status" clearable/>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-card v-if="tasksSettings.filter" class="mb-3">
      <v-card-actions class="py-0 px-5">
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field v-model="tasksSettings.search" label="Meklēt" prepend-icon="fa-search" single-line hide-details/>
          </v-col>
          <v-col cols="12" md="2">
            <v-select v-model="tasksSettings.status" label="Status" prepend-icon="fa-toggle-on" :items="tasks" item-text="status" clearable/>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-card v-if="advertisementsSettings.calendar" class="mb-3">
      <v-card-actions class="py-0">
        <v-row>
          <v-col class="py-0 px-0">
            <v-sheet>
              <v-toolbar flat>
                <v-btn
                  outlined
                  class="mr-4"
                  color="grey darken-2"
                  @click="setToday"
                >
                  Today
                </v-btn>
                <v-btn
                  fab
                  text
                  mdall
                  color="grey darken-2"
                  @click="prev"
                >
                  <v-icon mdall>fa-chevron-left</v-icon>
                </v-btn>
                <v-btn
                  fab
                  text
                  mdall
                  color="grey darken-2"
                  @click="next"
                >
                  <v-icon mdall>fa-chevron-right</v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.advertisementsCalendar">
                  {{ $refs.advertisementsCalendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu bottom right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      color="grey darken-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span>{{ calendar.typeToLabel[calendar.type] }}</span>
                      <v-icon right>fa-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="calendar.type = 'day'">
                      <v-list-item-title>Day</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="calendar.type = 'week'">
                      <v-list-item-title>Week</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="calendar.type = 'month'">
                      <v-list-item-title>Month</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>
            </v-sheet>
            <v-sheet outlined>
              <v-calendar
                locale="lv"
                ref="advertisementsCalendar"
                v-model="calendar.focus"
                :events="advertisementsSettings.events"
                :event-color="getEventColor"
                :type="calendar.type"
                @click:event="showEvent"
                @click:more="viewDay"
                @click:date="viewDay"
              ></v-calendar>
              <v-menu
                v-model="calendar.selectedOpen"
                :close-on-content-click="false"
                :activator="calendar.selectedElement"
                offset-x
                max-width="500"
              >
                <v-card flat max-width="500">
                  <v-toolbar color="primary" dark flat>
                    <v-toolbar-title v-html="calendar.selectedEvent.name"></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon :to="{name: 'advertisementShow', params: {id: calendar.selectedEvent.id}}">
                      <v-icon>fa-edit</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <span v-html="calendar.selectedEvent.description"></span>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      text
                      color="error"
                      @click="calendar.selectedOpen = false"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-card>
      <v-tabs>
        <v-tab>Uzņēmums</v-tab>
        <v-tab @click="getAdvertisements">Reklāmas</v-tab>
        <v-tab @click="getTasks">Uzdevumi</v-tab>
        <v-tab @click="getComments">Komentāriji</v-tab>
        <v-tab @click="getLog">Log</v-tab>
        <v-tab @click="getSpreadsheet">Facebook</v-tab>
        <v-tab-item>
          <v-card outlined>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field label="Nosaukums" v-model="company.name" outlined disabled/>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field label="Budžets" v-model="company.budget" outlined disabled/>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field label="Budžets datums" v-model="company.budget_date" outlined disabled/>
                </v-col>
                <v-col cols="12" lg="3" class="d-flex justify-space-between">
                  <v-switch v-model="company.ad_account" value="JKonsult" label="JKonsult" disabled></v-switch>
                  <v-switch v-model="company.ad_account" value="Klients" label="Klients" disabled></v-switch>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field label="Tālrunis" v-model="company.phone" outlined disabled/>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field label="E-pasts" v-model="company.email" outlined disabled/>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field label="Vaditajs" v-model="company.director" outlined disabled/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field label="Juridiskā adrese" v-model="company.legal_address" outlined disabled/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field label="Faktiskā adrese" v-model="company.actual_address" outlined disabled/>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field label="Reģistrācijas numurs" v-model="company.reg_number" outlined disabled/>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field label="PVN maksātāja numurs" v-model="company.vat_reg_number" outlined disabled/>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field label="Kredītiestādes nosaukums" v-model="company.bank_name" outlined disabled/>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field label="Kods SWIFT/BIC" v-model="company.bank_swift_bic" outlined disabled/>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field label="Konta numurs" v-model="company.bank_iban" outlined disabled/>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-text-field label="Spreadsheet" v-model="company.spreadsheet" outlined disabled/>
                </v-col>
                <v-col cols="12" md="8" lg="9">
                  <v-textarea label="Apraksts" v-model="company.description" outlined disabled/>
                </v-col>
                <v-col cols="12" md="4" lg="3">
                  <v-color-picker
                    v-model="company.color"
                    mode="hexa"
                    dot-size="25"
                    swatches-max-height="200"
                    disabled
                  ></v-color-picker>
                </v-col>
              </v-row>
            </v-card-text>
            <AlertResponse :response="alert"/>
            <v-card-actions>
              <v-btn color="primary" :to="{name: 'companyEdit', params: {id: this.$route.params.id}}">Update</v-btn>
              <v-spacer/>
              <v-btn color="error" @click="deleteCompany">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card outlined>
            <v-toolbar dense flat>
              <v-spacer></v-spacer>
              <v-btn icon :to="{name: 'advertisementCreate'}" color="primary">
                <v-icon>fa-plus</v-icon>
              </v-btn>
              <v-btn icon color="primary" @click="advertisementsSettings.filter = !advertisementsSettings.filter">
                <v-icon>fa-sliders-h</v-icon>
              </v-btn>
              <v-btn icon color="primary" @click="advertisementsSettings.calendar = !advertisementsSettings.calendar">
                <v-icon>fa-calendar</v-icon>
              </v-btn>
            </v-toolbar>
            <v-data-table
              :headers="headersAdvertisements"
              :items="advertisements"
              item-key="item.id"
              :loading="advertisementsSettings.loading"
              loading-text="Notiek ielāde... Lūdzu, uzgaidiet"
              :search="advertisementsSettings.search">
              <template v-slot:item.actions="{ item }">
                <v-btn icon color="primary" :to="{name: 'advertisementShow', params: {id: item.id}}">
                  <v-icon>fa-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card outlined>
            <v-toolbar dense flat>
              <v-spacer></v-spacer>
              <v-btn icon :to="{name: 'taskCreate'}" color="primary">
                <v-icon>fa-plus</v-icon>
              </v-btn>
              <v-btn icon color="primary" @click="tasksSettings.filter = !tasksSettings.filter">
                <v-icon>fa-sliders-h</v-icon>
              </v-btn>
            </v-toolbar>
            <v-data-table
              :headers="headersTasks"
              :items="tasks"
              item-key="item.id"
              :loading="tasksSettings.loading"
              loading-text="Notiek ielāde... Lūdzu, uzgaidiet"
              :search="tasksSettings.search">
              <template v-slot:item.actions="{ item }">
                <v-btn icon color="primary" :to="{name: 'taskShow', params: {id: item.id}}">
                  <v-icon>fa-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card outlined>
            <v-data-table
              :headers="headersComments"
              :items="comments"
              item-key="item.id"
              :loading="commentsSettings.loading"
              loading-text="Notiek ielāde... Lūdzu, uzgaidiet"
              :search="commentsSettings.search">
              <template v-slot:item.actions="{ item }">
                <v-btn :id="item.id" icon color="primary">
                  <v-icon>fa-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card outlined>
            <v-data-table
              :headers="headersLogs"
              :items="logs"
              item-key="item"
              :loading="logsSettings.loading"
              loading-text="Notiek ielāde... Lūdzu, uzgaidiet"
            />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-data-table
            :headers="headersSpreadsheet"
            :items="spreadsheets"
            item-key="item.id"
            :loading="advertisementsSettings.loading"
            loading-text="Notiek ielāde... Lūdzu, uzgaidiet"
            disable-pagination
          />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
import axios from '@/axios'
import AlertResponse from '@/components/AlertResponse'

export default {
  name: 'CompanyShow',
  components: {
    AlertResponse
  },
  data: () => ({
    companyId: null,
    alert: null,
    calendar: {
      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day'
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      colors: ['pink', 'purple', 'deep-purple', 'indigo', 'blue', 'light-blue', 'cyan', 'teal', 'green', 'light-green', 'lime', 'yellow', 'amber', 'orange', 'deep-orange', 'brown', 'blue-grey']
    },
    company: {
      ad_account: '',
      name: '',
      description: '',
      phone: '',
      email: '',
      director: '',
      reg_number: '',
      vat_reg_number: '',
      legal_address: '',
      actual_address: '',
      bank_name: '',
      bank_swift_bic: '',
      bank_iban: '',
      budget: '',
      budget_date: '',
      color: '',
      sort_order: 100,
      status: 1,
      spreadsheet: ''
    },
    advertisementsSettings: {
      loading: true,
      filter: false,
      calendar: false,
      search: '',
      status: '',
      events: []
    },
    tasksSettings: {
      loading: true,
      filter: false,
      search: '',
      status: ''
    },
    commentsSettings: {
      loading: true,
      filter: false,
      search: '',
      status: ''
    },
    logsSettings: {
      loading: true,
      filter: false,
      search: '',
      status: ''
    },
    advertisements: [],
    invoices: [],
    tasks: [],
    comments: [],
    logs: [],
    spreadsheets: []
  }),
  computed: {
    headersAdvertisements () {
      return [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Date Start',
          value: 'date_start'
        },
        {
          text: 'Date End',
          value: 'date_end'
        },
        {
          text: 'Target',
          value: 'target_type'
        },
        {
          text: 'Budget',
          value: 'budget'
        },
        {
          text: 'Status',
          value: 'status',
          filter: value => {
            if (this.status === 0 || this.status === 1) {
              return value === this.status
            }
            if (!this.status) return true
          }
        },
        {
          text: 'Darbības',
          value: 'actions'
        }
      ]
    },
    headersTasks () {
      return [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Date Start',
          value: 'date_start'
        },
        {
          text: 'Date End',
          value: 'date_end'
        },
        {
          text: 'Status',
          value: 'status',
          filter: value => {
            if (this.status === 0 || this.status === 1) {
              return value === this.status
            }
            if (!this.status) return true
          }
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    },
    headersComments () {
      return [
        {
          text: 'ID',
          value: 'id'
        },

        {
          text: 'User',
          value: 'user.name'
        },
        {
          text: 'Text',
          value: 'text'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    },
    headersLogs () {
      return [
        {
          text: 'Model',
          value: 'type'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Action',
          value: 'action'
        },
        {
          text: 'User',
          value: 'user'
        },
        {
          text: 'Created At',
          value: 'created_at'
        }
      ]
    },
    headersSpreadsheet () {
      return [
        {
          text: 'Campaign Name',
          value: 'Campaign Name'
        },
        {
          text: 'Amount Spent',
          value: 'Amount Spent'
        },
        {
          text: 'Reach',
          value: 'Reach'
        },
        {
          text: 'Impressions',
          value: 'Impressions'
        },
        {
          text: 'Unique Outbound Clicks',
          value: 'Unique Outbound Clicks'
        },
        {
          text: 'Unique Outbound CTR (Click-Through Rate)',
          value: 'Unique Outbound CTR (Click-Through Rate)'
        },
        {
          text: 'Cost per Unique Outbound Click',
          value: 'Cost per Unique Outbound Click'
        },
        {
          text: 'Post Comments',
          value: 'Post Comments'
        },
        {
          text: 'Post Saves',
          value: 'Post Saves'
        },
        {
          text: 'Post Shares',
          value: 'Post Shares'
        },
        {
          text: 'Leads',
          value: 'Leads'
        },
        {
          text: 'Cost per Lead',
          value: 'Cost per Lead'
        }
      ]
    }
  },
  updated () {
    if (this.advertisementsSettings.calendar) {
      this.$refs.advertisementsCalendar.checkChange()
    }
  },
  methods: {
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    viewDay ({ date }) {
      this.calendar.focus = date
      this.calendar.type = 'day'
    },
    setToday () {
      this.calendar.focus = ''
    },
    prev () {
      this.$refs.advertisementsCalendar.prev()
    },
    next () {
      this.$refs.advertisementsCalendar.next()
    },
    getEventColor (event) {
      return event.color
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.calendar.selectedEvent = event
        this.calendar.selectedElement = nativeEvent.target
        setTimeout(() => {
          this.calendar.selectedOpen = true
        }, 10)
      }

      if (this.calendar.selectedOpen) {
        this.calendar.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    getCompany () {
      this.companyId = this.$route.params.id
      axios.get('companies/' + this.companyId).then(response => {
        this.company = response.data
      })
    },
    deleteCompany () {
      const isDelete = confirm('Vai vēlaties dzēst ierakstu?')
      if (isDelete) {
        axios.delete('companies/' + this.companyId).then(response => {
          this.alert = response.data
          setTimeout(() => this.$router.push({ name: 'companyIndex' }), 2000)
        })
      }
    },
    getAdvertisements () {
      this.advertisementsSettings.loading = true
      axios.get('companies/' + this.companyId + '/advertisements').then(response => {
        this.advertisements = response.data
        this.advertisementsSettings.events = []
        this.advertisementsSettings.loading = false

        for (let i = 0; i < this.advertisements.length; i++) {
          const start = new Date(`${this.advertisements[i].date_start}T00:00:00`)
          const end = new Date(`${this.advertisements[i].date_end}T00:00:00`)
          let color = 'error'

          if (this.advertisements[i].status === 1) {
            color = this.calendar.colors[this.rnd(0, this.calendar.colors.length - 1)]
          }

          this.advertisementsSettings.events.push({
            id: this.advertisements[i].id,
            name: this.advertisements[i].name + ' ' + start.toLocaleDateString('en') + ' - ' + end.toLocaleDateString('en'),
            description: this.advertisements[i].description,
            start: start,
            end: end,
            color: color
          })
        }
      })
    },
    getTasks () {
      this.tasksSettings.loading = true
      axios.get('companies/' + this.companyId + '/tasks').then(response => {
        this.tasks = response.data
        this.tasksSettings.loading = false
      })
    },
    getComments () {
      this.commentsSettings.loading = true
      axios.get('companies/' + this.companyId + '/comments').then(response => {
        this.comments = response.data
        this.commentsSettings.loading = false
      })
    },
    getLog () {
      this.commentsSettings.loading = true
      axios.get('companies/' + this.companyId + '/logs').then(response => {
        this.logs = response.data
        this.logsSettings.loading = false
      })
    },
    getSpreadsheet () {
      this.advertisementsSettings.loading = true
      axios.get('companies/' + this.companyId + '/spreadsheet').then(response => {
        this.spreadsheets = response.data
        this.advertisementsSettings.loading = false
      }).catch(error => {
        console.log(error)
      })
    }
  },
  created () {
    this.getCompany()
  }
}
</script>

<style scoped>
</style>
